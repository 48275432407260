html {
  scroll-behavior: smooth !important;
  font-family: "Montserrat", "Poppins", "Roboto", "Helvetica", "Arial",
    sans-serif !important;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(
    to bottom,
    rgba(49, 50, 111, 0.5),
    rgba(49, 50, 131, 0.5)
  ) !important;
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background: linear-gradient(to top, #31326f, #313283) !important;
}
